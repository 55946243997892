import React from 'react';
import {
  CloudUploadIcon,
  DocumentReportIcon,
  IdentificationIcon,
  ShareIcon,
  PencilAltIcon,
  DocumentTextIcon,
  CalendarIcon,
  ClockIcon,
} from '@heroicons/react/outline';
import CTAFancy from '../../../components/cta/fancy';
import Layout from '../../../components/layout';
import Header from '../../../components/header';
import SEO from '../../../components/seo';
import FeatureBlock from '../../../components/featureBlocks';

const features = [
  {
    name: 'Customers portal',
    description:
      'Get a customer portal where your customers can download their invoices and statements.',
    icon: IdentificationIcon,
  },
  {
    name: 'Compliant invoices',
    description:
      'Generate multi-language compliant invoices. With support for electronic invoices.',
    icon: DocumentTextIcon,
  },
  {
    name: 'Auto-reconciliation',
    description:
      'Automatically reconcile the majority of your transactions, and use a simple interface for manual changes.',
    icon: CloudUploadIcon,
  },
  {
    name: 'Stop jumping between bank portals',
    description:
      'Monitor your transactions and balances from a single dashboard.',
    icon: PencilAltIcon,
  },
  {
    name: 'Easy collection',
    description:
      "Use our smart reminders and keep track of any overdue invoices and customer's debt.",
    icon: ClockIcon,
  },
  {
    name: 'Bulk operations',
    description:
      'Create and send invoice and reminders for multiple invoices in just a few clicks.',
    icon: CalendarIcon,
  },
  {
    name: 'Reporting',
    description:
      'Customer analysis, TAX reporting. Use our advanced filters and quick views to get an instant picture of your company.',
    icon: DocumentReportIcon,
  },
  {
    name: 'Easy exports and integrations',
    description:
      'Export your data to CSV or connect your system to our APIs. Easily integrate with your accounting software.',
    icon: ShareIcon,
  },
];

function AccountsReceivable({ location }) {
  return (
    <Layout>
      <SEO
        title="Accounts Receivable Automation"
        description="Automate your accounts receivable. Create and send compliant bulk invoices, automate reconciliation and reminders."
        path={location.pathname}
      />
      <Header
        title={
          <h1 className="text-4xl tracking-tight font-bold text-gray-200 sm:text-4xl md:text-6xl">
            <span className="block">Ditch the spreadsheet. </span>
            <span className="inline bg-gradient-to-r from-purple-tb-400 bg-purple-tb-400 via-orange-tb-400 bg-clip-text font-display tracking-tight text-transparent">
              Accounts Receivable automated.
            </span>
          </h1>
        }
        text="Create and send compliant bulk invoices, automate reconciliation and reminders. Connect your CRM to streamline your Sales-to-Finance motion."
      />
      <FeatureBlock
        features={features}
        title="Easy invoicing and collection for your finance team."
      />

      <CTAFancy
        cta=" Invoice. Collect. Reconcile."
        ctaLink="Automate your receivables today."
      />
    </Layout>
  );
}

export default AccountsReceivable;
