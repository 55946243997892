import React from 'react';

export default function Example({ title, section, text }) {
  return (
    <div>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">
            {section}
          </h2>
          {title}
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-300">{text}</p>
        </div>
      </div>
    </div>
  );
}
