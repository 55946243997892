import React from 'react';
import { Link } from 'gatsby';
import { siteMap } from '../../utils/sitemap';

function FancyCTA({ cta, ctaLink }) {
  return (
    <div>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-gray-tb-400 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block bg-gradient-to-r from-purple-tb-400 to-orange-tb-400 bg-clip-text text-transparent">
                  {cta}
                </span>
                <span className="block">{ctaLink}</span>
              </h2>
              <Link
                to={siteMap.DEMO.link}
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-purple-tb-400 hover:bg-purple-tb-400 hover:text-gray-200"
              >
                Sign up for free
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FancyCTA;
